
import { defineComponent } from "vue";
import {
  castleOrgData,
  conv_readable_pieces,
  conv_readable_moves,
  conv_readable_capture,
  conv_readable_hand,
} from "@/modules/castle";

export default defineComponent({
  props: {},
  setup(props) {
    return {
      props,
      castleOrgData,
      keyTips: {
        pieces: "盤上の駒[AND条件]",
        hand: "駒台の駒[AND条件]",
        hand_exclude: "駒台にあってはいけない駒[NOT OR条件]",
        moves: "指し手[OR条件]",
        capture: "捕獲した駒[OR条件]",
        tags_required: "成立に必要なタグ[AND条件]",
        tags_exclude:
          "そのタグが既に存在していたら成立扱いにしない[NOT OR条件]",
        tags_disable: "無効化させるタグ",
        special: "特殊",
        tesuu_max: "最大手数制限",
        hide: "非表示",
        noturn: "先手番/後手番のルール対生成をしない",
      },
    };
  },
  methods: {
    conv_readable_pieces,
    conv_readable_moves,
    conv_readable_capture,
    conv_readable_hand,
  },
  components: {},
});
