
import { defineComponent, SetupContext, reactive } from "vue";
import CastleList from "@/components/CastleList.vue";

export default defineComponent({
  props: {},
  setup(props, ctx: SetupContext) {
    const data = reactive({});
    return {
      props,
      data,
      ctx,
    };
  },
  components: {
    CastleList,
  },
});
