
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import GameSelect from "@/components/GameSelect.vue";
import GameFlex from "@/components/GameFlex.vue";
import PR from "@/components/PR.vue";

export default defineComponent({
  props: {
    tournament: {
      type: String,
      required: true,
    },
    limitTimeDur: {
      type: Number,
      required: false,
      default: () => Infinity,
    },
    limitNumber: {
      type: Number,
      required: false,
      default: () => Infinity,
    },
    hideTags: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hideGraph: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hideTools: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hideComments: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hideEnd: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    gameNameInclude: {
      type: String,
      required: false,
      default: () => "",
    },
    gameIdInclude: {
      type: String,
      required: false,
      default: () => "",
    },
  },
  setup(props) {
    const data = reactive({
      show: true,
    });
    const router = useRouter();
    const changeGame = (msg: { tournament: string; gameid: string }) => {
      router.replace(`/${msg.tournament}/${msg.gameid}`);
    };
    return {
      props,
      data,
      showoption: false,
      changeGame,
      changeQuery: (newprops: Partial<typeof props>): void => {
        const p = Object.assign({}, props, newprops);
        router.replace({
          name: "KifuMulti",
          params: {
            tournament: p.tournament,
          },
          query: Object.assign(
            {},
            0 < p.limitTimeDur && p.limitTimeDur < 1e9
              ? {
                  lt: `${p.limitTimeDur}`,
                }
              : {},
            0 < p.limitNumber && p.limitNumber < 1e9
              ? {
                  ln: `${p.limitNumber}`,
                }
              : {},
            p.hideTags ? { tags: "0" } : {},
            p.hideGraph ? { graph: "0" } : {},
            p.hideTools ? { tools: "0" } : {},
            p.hideComments ? { comments: "0" } : {},
            p.hideEnd ? { end: "0" } : {},
            p.gameNameInclude ? { name: p.gameNameInclude } : {},
            p.gameIdInclude ? { id: p.gameIdInclude } : {}
          ),
        });
      },
    };
  },
  components: {
    GameSelect,
    GameFlex,
    PR,
  },
});
