
import { defineComponent, reactive, computed } from "vue";
import { useRouter } from "vue-router";
import GameSelect from "@/components/GameSelect.vue";
import GameFlex from "@/components/GameFlex.vue";
import GameList from "@/components/GameList.vue";
import PR from "@/components/PR.vue";

export default defineComponent({
  props: {
    tournament: {
      type: String,
      required: true,
    },
    limitTimeDur: {
      type: Number,
      required: false,
      default: () => Infinity,
    },
    limitNumber: {
      type: Number,
      required: false,
      default: () => 20,
    },
    hideTags: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hideGraph: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hideTools: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hideComments: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hideEnd: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    gameNameInclude: {
      type: String,
      required: false,
      default: () => "",
    },
    gameIdInclude: {
      type: String,
      required: false,
      default: () => "",
    },
  },
  setup(props) {
    const router = useRouter();
    const data = reactive({
      linkKifuMulti: computed(() => `/${props.tournament}/multi`),
      linkKifuMultiAll: computed(() => `/${props.tournament}/multiall`),
    });
    const changeGame = (msg: { tournament: string; gameid: string }) => {
      router.replace(`/${msg.tournament}/${msg.gameid}`);
    };
    return {
      props,
      data,
      changeGame,
    };
  },
  components: {
    GameSelect,
    GameFlex,
    GameList,
    PR,
  },
});
