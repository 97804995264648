
import { defineComponent, reactive, SetupContext, watch } from "vue";
import { useStore } from "vuex";
import { JKFPlayer } from "json-kifu-format";
import { IMoveFormat } from "json-kifu-format/dist/src/Formats";
import { getKifuMirrorUrl, getKifuOrgUrl } from "@/modules/kifuurl";
import { toPackedSfenWeb } from "@/modules/psfenw";
import { Stage } from "@/modules/ShogiGL/stage";
import TagBar from "@/components/TagBar.vue";
import TesuuSel from "@/components/Kifu/TesuuSel.vue";
import ScoreGraph from "@/modules/scoregraph";
import iconCaretLeftRaw from "!!raw-loader!@tabler/icons/icons/caret-left.svg";
import iconChevronsLeftRaw from "!!raw-loader!@tabler/icons/icons/chevrons-left.svg";
import iconArrowBarToLeftRaw from "!!raw-loader!@tabler/icons/icons/arrow-bar-to-left.svg";
import iconCaretRightRaw from "!!raw-loader!@tabler/icons/icons/caret-right.svg";
import iconChevronsRightRaw from "!!raw-loader!@tabler/icons/icons/chevrons-right.svg";
import iconArrowBarToRightRaw from "!!raw-loader!@tabler/icons/icons/arrow-bar-to-right.svg";
import iconRotateRaw from "!!raw-loader!@tabler/icons/icons/rotate.svg";
import iconTwitterRaw from "!!raw-loader!@tabler/icons/icons/brand-twitter.svg";
import iconCopyRaw from "!!raw-loader!@tabler/icons/icons/copy.svg";
import iconDownloadRaw from "!!raw-loader!@tabler/icons/icons/download.svg";
import iconLogoutRaw from "!!raw-loader!@tabler/icons/icons/logout.svg";
import iconLinkRaw from "!!raw-loader!@tabler/icons/icons/link.svg";
import iconBrushRaw from "!!raw-loader!@tabler/icons/icons/brush.svg";

export default defineComponent({
  props: {
    tournament: {
      type: String,
      required: true,
    },
    gameid: {
      type: String,
      required: true,
    },
    gamename: {
      type: String,
      required: false,
    },
    ply: {
      type: Number,
      required: true,
    },
    hideTags: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hideGraph: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hideTools: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    hideComments: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    lightEnd: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  setup(props, ctx: SetupContext) {
    const data = reactive({
      jkfstr: `{"header":{},"moves":[{}]}`,
      tesuu: 0,
      tesuuMax: 0,
      kifustr: "",
      ply: props.ply,
      error: "",
      rotated: false,
      intervalId: 0,
      inGame: false,
      hasClipboard: !!navigator?.clipboard,
      activated: false,
      updated: 0,
      showDiag: false,
    });
    const store = useStore();
    const getPSfenWB64 = (): string => {
      const player = JKFPlayer.parseJKF(data.jkfstr);
      player.goto(data.tesuu);
      let mvply = { move: player.getMove(), tesuu: player.tesuu };
      if (player.tesuu > 0 && !mvply.move) {
        mvply = {
          move: player.getMove(player.tesuu - 1),
          tesuu: player.tesuu - 1,
        };
      }
      if (player.tesuu > 1 && !mvply.move) {
        mvply = {
          move: player.getMove(player.tesuu - 2),
          tesuu: player.tesuu - 2,
        };
      }
      return toPackedSfenWeb(player.shogi, mvply.move, mvply.tesuu).toB64();
    };
    const getComment = (): string =>
      [data.error, ...JKFPlayer.parseJKF(data.jkfstr).getComments(data.tesuu)]
        .filter((e) => e)
        .join("\n");
    const getKifuurl = (): string =>
      getKifuMirrorUrl(props.tournament, props.gameid);
    const getKifuorgurl = (): string =>
      getKifuOrgUrl(props.tournament, props.gameid);
    const plyGo = (plyRel: number | string) => {
      const oldTesuu = data.tesuu;
      const newTesuu = Math.max(Math.min(oldTesuu + +plyRel, data.tesuuMax), 0);
      const newDataPly = newTesuu !== data.tesuuMax ? newTesuu : NaN;
      data.tesuu = newTesuu;
      data.ply = newDataPly;
      if (oldTesuu !== newTesuu) {
        ctx.emit("change-ply", {
          tournament: props.tournament,
          gameid: props.gameid,
          gamename: props.gamename,
          ply: newDataPly,
        });
      }
    };
    const plyGoto = (plyAbs: number | string) => {
      const oldTesuu = data.tesuu;
      const newTesuu = Math.max(Math.min(+plyAbs, data.tesuuMax), 0);
      const newDataPly = newTesuu !== data.tesuuMax ? newTesuu : NaN;
      data.tesuu = newTesuu;
      data.ply = newDataPly;
      if (oldTesuu !== newTesuu) {
        ctx.emit("change-ply", {
          tournament: props.tournament,
          gameid: props.gameid,
          gamename: props.gamename,
          ply: newDataPly,
        });
      }
    };
    const tesuuChange = (msg: { ply: number }) => {
      plyGoto(msg.ply);
    };
    const tesuuChangeEvent = (event: Event) => {
      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLSelectElement
      ) {
        plyGoto(event.target.value);
      }
    };
    const loadEmpty = () => {
      data.jkfstr = `{"header":{},"moves":[{}]}`;
      data.kifustr = "";
      data.tesuuMax = 0;
      data.tesuu = 0;
    };
    const updateData = () => {
      const tournament = props.tournament;
      const gameId = props.gameid;
      const csa = store.getters["shogiServer/getRawCsa"](tournament, gameId);
      const jkf = store.getters["shogiServer/getJkf"](tournament, gameId);
      const tesuuMax = store.getters["shogiServer/getTesuuMax"](
        tournament,
        gameId
      );
      const gameEnd = store.getters["shogiServer/getGameEnd"](
        tournament,
        gameId
      );
      const tesuu = Math.max(
        Math.min(Number.isNaN(data.ply) ? Infinity : data.ply, tesuuMax),
        0
      );
      [
        data.kifustr,
        data.error,
        data.inGame,
        data.tesuuMax,
        data.jkfstr,
        data.tesuu,
        data.activated,
      ] = [csa, "", !gameEnd, tesuuMax, jkf, tesuu, true];
      setTimeout(() => {
        // TesuuSel用の遅延更新呼び出し
        data.updated = new Date().valueOf();
      }, 0);
    };
    updateData();
    const loadKifu = () => {
      const tournament = props.tournament;
      const gameId = props.gameid;
      if (!tournament || !gameId) {
        return;
      }
      updateData();
      store.dispatch("shogiServer/fetchCsa", {
        tournament,
        gameId,
        callback: updateData,
      });
    };
    data.intervalId = window.setInterval(
      loadKifu,
      props.tournament === "floodgate" ? 5000 : 1000
    );
    const moveToReadableKifu = (mv: IMoveFormat): string => {
      return JKFPlayer.moveToReadableKifu(mv);
    };
    const kifuUrlOpenEvent = () => {
      window.open(getKifuorgurl(), "_blank");
    };
    // 盤面反転ボタン
    const doRotate = () => {
      data.rotated = !data.rotated;
    };
    // 棋譜コピーボタン
    const doCopy = () => {
      if (navigator?.clipboard) {
        (navigator?.clipboard as
          | undefined
          | { writeText(str: string): Promise<unknown> })?.writeText(
          data.kifustr
        );
      }
    };
    // 棋譜URLコピーボタン
    const doCopyURL = () => {
      if (navigator?.clipboard) {
        (navigator?.clipboard as
          | undefined
          | { writeText(str: string): Promise<unknown> })?.writeText(
          getKifuorgurl()
        );
      }
    };
    // 棋譜ダウンロードボタン
    const doDownload = () => {
      const link = document.createElement("a");
      link.download = `${props.gameid}.csa`;
      const blob = new Blob([data.kifustr], {
        type: "application/octet-stream",
      });
      link.href = URL.createObjectURL(blob);
      link.click();
    };
    // ツイートボタン
    const doTweet = () => {
      const player = JKFPlayer.parseJKF(data.jkfstr);
      const readableKifu = player.getReadableKifu(data.tesuu);
      const tweetProp =
        props.tournament === "floodgate"
          ? {
              text: `${props.gamename} ${data.tesuu}手目 ${readableKifu}\n\n\n`,
              url: new URL(
                `./floodgate.php?tn=${props.tournament}&gi=${
                  props.gameid
                }&p=${getPSfenWB64()}&gn=${encodeURIComponent(
                  props.gamename || ""
                )}`,
                window.location.href
              ).href,
              hashtags: "将棋,floodgate",
              via: "",
            }
          : {
              text: `${props.gamename} ${data.tesuu}手目 ${readableKifu}\n\n\n`,
              url: new URL(
                `./denryu.php?tn=${props.tournament}&gi=${
                  props.gameid
                }&p=${getPSfenWB64()}&gn=${encodeURIComponent(
                  props.gamename || ""
                )}`,
                window.location.href
              ).href,
              hashtags: "将棋,電竜戦",
              via: "DenryuSen",
            };
      window.open(
        `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          tweetProp.text
        )}${tweetProp.url ? `&url=${encodeURIComponent(tweetProp.url)}` : ""}${
          tweetProp.hashtags
            ? `&hashtags=${encodeURIComponent(tweetProp.hashtags)}`
            : ""
        }${tweetProp.via ? `&via=${encodeURIComponent(tweetProp.via)}` : ""}`,
        "_blank",
        "noopener=yes"
      );
    };
    const doDiag = () => {
      data.showDiag = !data.showDiag;
    };
    // 情報ダイアログボタン
    const doInfoDiag = () => {
      // TODO
    };
    loadKifu();
    watch(
      () => props.tournament,
      () => {
        data.ply = props.ply;
        loadKifu();
      }
    );
    watch(
      () => props.gameid,
      () => {
        data.ply = props.ply;
        loadKifu();
      }
    );
    watch(
      () => props.ply,
      () => {
        data.ply = props.ply;
        plyGoto(isNaN(props.ply) ? Infinity : props.ply);
      }
    );
    return {
      props,
      data,
      getComment,
      getKifuurl,
      getKifuorgurl,
      getPSfenWB64,
      loadEmpty,
      loadKifu,
      moveToReadableKifu,
      doRotate,
      doTweet,
      doInfoDiag,
      doCopy,
      doCopyURL,
      doDownload,
      doDiag,
      plyGo,
      plyGoto,
      kifuUrlOpenEvent,
      tesuuChange,
      tesuuChangeEvent,
      iconCaretLeftRaw,
      iconChevronsLeftRaw,
      iconArrowBarToLeftRaw,
      iconCaretRightRaw,
      iconChevronsRightRaw,
      iconArrowBarToRightRaw,
      iconRotateRaw,
      iconTwitterRaw,
      iconCopyRaw,
      iconDownloadRaw,
      iconLogoutRaw,
      iconLinkRaw,
      iconBrushRaw,
    };
  },
  beforeUnmount() {
    if (this.data.intervalId) {
      clearInterval(this.data.intervalId);
      this.data.intervalId = 0;
    }
  },
  components: {
    TagBar,
    TesuuSel,
    ScoreGraph,
    Stage,
  },
});
