
import { defineComponent } from "vue";
import PR from "@/components/PR.vue";

export default defineComponent({
  name: "Home",
  setup(props) {
    return {
      props,
      licenses: require("@/assets/licenses.json"),
    };
  },
  components: {
    PR,
  },
});
